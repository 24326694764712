import React, {useState, useEffect} from "react"
import { Link } from "gatsby"
import {Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, Logo} from './NavbarElements'
import { FaBars } from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import { Link as LinkS} from 'react-scroll';
import {animateScroll as scroll} from 'react-scroll';
import esg from '../../assets/photos/esg-logo.png'

import { FormattedMessage } from 'react-intl'
import { Trigger } from '../common/Trigger'



const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop()
    }

    return (
        <>
        <IconContext.Provider value={{color: '#fff'}}>
          <Nav scrollNav={scrollNav}>
             <NavbarContainer>
                 <NavLogo to='/' onClick={toggleHome}>
                    <Logo src={esg} alt='etihad'/>
                 </NavLogo>
                 <MobileIcon onClick={toggle}>
                     <FaBars />
                 </MobileIcon>
                 <NavMenu>
                 <NavItem>
                         <NavLinks to="/"><FormattedMessage id="Home" /></NavLinks>
                     </NavItem>
                     <NavItem>
                         <NavLinks to="/about"><FormattedMessage id="About" /></NavLinks>
                     </NavItem>
                     <NavItem>
                         <NavLinks to="/services"><FormattedMessage id="Services" /></NavLinks>
                     </NavItem>
                     <NavItem>
                         <NavLinks to="/projects"><FormattedMessage id="Projects" /></NavLinks>
                     </NavItem>
                     <NavItem>
                         <NavLinks to="/impressum"><FormattedMessage id="Impressum" /></NavLinks>
                     </NavItem>
                     <NavItem>
                         <NavLinks to="/contact"><FormattedMessage id="ContactUs" /></NavLinks>
                     </NavItem>
                 </NavMenu>
                 {/* <NavBtn>
                     <NavBtnLink to="/">Sign In</NavBtnLink>
                 </NavBtn> */}
                 <NavBtn>
                     <Trigger />
                 </NavBtn>
                 
             </NavbarContainer> 
          </Nav>
          </IconContext.Provider>

        </>
    )
}

export default Navbar