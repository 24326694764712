import React, {useState} from "react"
import Header from "../../Header"
import Navbar from "../../Navbar"
import Sidebar from "../../Sidebar"


import Footer from "../../Footer"
import Email from "../../Email"
import { GlobalStyle } from "../../styles/GlobalStyles"
import styled from 'styled-components'

import ar from 'react-intl/locale-data/ar'
import en from 'react-intl/locale-data/en'
import de from 'react-intl/locale-data/de'

import { addLocaleData, IntlProvider } from 'react-intl'
import localEng from '../../../messages/en.json'
import localAr from '../../../messages/ar.json'
import localDe from '../../../messages/de.json'

import { Context } from '../Context'
import Provider from './Provider'
import './layout.css'

addLocaleData(ar)
addLocaleData(de)
addLocaleData(en)


const Layout = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
    setIsOpen(!isOpen)
  }
 return (
	<>
	<Provider>
		<Context.Consumer>
			{({ lang }) => (
				
				<IntlProvider locale={lang} messages={lang === 'en' ? localEng : lang === 'ar' ? localAr : localDe} >
					<Global lang={lang}>
                        <GlobalStyle />
						{/* <Header /> */}
						<Sidebar isOpen={isOpen} toggle={toggle}/>
                        <Navbar toggle={toggle}/>
						{children}
                        <Email />
                        <Footer />
						
					</Global>
				</IntlProvider>
			)}
		</Context.Consumer>
	</Provider>
	</>
  )
}

const Global = styled.div`
    /* font-family: 'Roboto', sans-serif; */
    ${({ lang }) => lang === 'ar' && `
        font-family: 'Almarai', sans-serif;    
    `}
`

export { Layout }
