import styled from 'styled-components'
import { Link } from "gatsby"


export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? '#1a2b3d' : 'transparent')}; 
  height: 80px;
  margin-top: 0; /* margin-top: -80px; */
  display: flex;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
      transition: 0.8s all ease;
  }
`

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 10px;
  max-width: 1100px;
`

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-weight: bold;
  text-decoration: none;

`

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: relative;
    top: 22%;
    right: 0;
    /* transform: translate(-100%, 60%); */
    font-size: 2rem;
    cursor: pointer;
    color: white;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
      display: none;
  }
`

export const NavItem = styled.li`
  height: 80px;
`

export const NavLinks = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 15px;
  
  &.active {
      border-bottom: 3px solid #bd0b27;
  }

  &:hover {
      color: #bd0b27;
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
      display: none;
  }
`

export const NavBtnLink = styled(Link)`
  border-radius: 50px;
  background: #bd0b27;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-ease-out;
  text-decoration: none;

  &:hover {
      transition: all 0.2s ease-in-out;
      background: #fff;
      color: #bd0b27;
  }

`

export const Logo = styled.img`
    margin-top: 0.1rem;
    height: 82px;
    width: 84px;

    @media screen and (max-width:768px) {
      height: 72px;
      width: 74px;
    }
`