import React from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import { FaFacebook, FaInstagram, FaFax, FaFacebookF } from 'react-icons/fa'
import { Link } from 'gatsby'
import { MdLocationOn, MdPhone, MdEmail} from 'react-icons/md'
import { ImPhoneHangUp } from 'react-icons/im'
import { RiPagesLine } from 'react-icons/ri'
import { FaMobileAlt } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'
import esg from '../assets/photos/esg-logo.png'


const Footer = () => {
    return (
      <FooterContainer>
          {/* <FooterSubscription>
              <FooterSubHeading>
                Contact us to know more about 
                the prices of our various services. 
              </FooterSubHeading>
              <FooterSubText>
                  Always ready to ship your vehicle!
              </FooterSubText>
              <Form>
                  <Button primary round >Contact Us</Button>
              </Form>
          </FooterSubscription> */}
          <FooterLinksContainer>
              <FooterLinksWrapper>

                <FooterLinksItems>
                    <Logo src={esg} alt='etihad'/>
                </FooterLinksItems>

                <FooterLinksItems>
                      <FooterLinksTitle><MdLocationOn/> <FormattedMessage id="Address" /></FooterLinksTitle>
                      <FooterEmail target="_blank" href="http://maps.google.com/?q=20539 Etihad Shipping Germany Gmbh, Billstraase 180, Hamburg, 20539">Etihad Shipping Germany GmbH</FooterEmail>
                      <FooterItem> Billstrasse 180 </FooterItem>
                      <FooterItem to=''>20539 Hamburg</FooterItem>
                </FooterLinksItems>

                <FooterLinksItems>
                      <FooterLinksTitle><MdPhone/>  <FormattedMessage id="Contacts" /></FooterLinksTitle>
                      <FooterEmail href="mailto:info@etihadshipping.de"> <MdEmail/> info@etihadshipping.de</FooterEmail>
                      <FooterEmail href="tel:+4940730913970"> <ImPhoneHangUp/> <FormattedMessage id="Tel" /> </FooterEmail>
                      <FooterEmail href="tel:+4916099108419"> <FaMobileAlt/> <FormattedMessage id="Mobile" /> </FooterEmail>
                      <FooterEmail href="tel:+49407309139716"> <FaFax/> <FormattedMessage id="Fax" /> </FooterEmail>
                </FooterLinksItems>

                <FooterLinksItems>
                      <FooterLinksTitle> <RiPagesLine/> <FormattedMessage id="QuickLinks" /> </FooterLinksTitle>
                      <FooterLink to='/'> <FormattedMessage id="Home" /> </FooterLink>
                      <FooterLink to='/about'> <FormattedMessage id="About" /> </FooterLink>
                      <FooterLink to='/services'> <FormattedMessage id="Services" /> </FooterLink>
                      <FooterLink to='/projects'> <FormattedMessage id="Projects" /> </FooterLink>
                      <FooterLink to='/impressum'> <FormattedMessage id="Impressum" /> </FooterLink>
                      <FooterLink to='/contact'> <FormattedMessage id="ContactUs" /> </FooterLink>
                </FooterLinksItems>

              
              </FooterLinksWrapper>
              
          </FooterLinksContainer>
          <SocialMedia>
              <SocialMediaWrap>
              <SocialIcons>
                      <SocialIconLink href="//www.facebook.com/etihadshipping" target="_blank" aria-label="Facebook"> <FaFacebook/></SocialIconLink>
                      <SocialIconLink href="//www.instagram.com/etihadshipping/" target="_blank" aria-label="Instagram"> <FaInstagram/></SocialIconLink>
            </SocialIcons>
    
            <WebsiteRights>
                 <FormattedMessage id="IntroH2" /> © {new Date().getFullYear()}
            </WebsiteRights>
            </SocialMediaWrap>
          </SocialMedia>
      </FooterContainer>
    )
}

export default Footer

const FooterContainer = styled.div`
    background-color: #1c2b40;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`


const FooterSubscription = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: white;
`


const FooterSubHeading = styled.p`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicoder', 'Lucida Grannde', 
    'Lucida Sans', Arial, Helvetica, sans-serif, sans-serif;
    margin-bottom: 24px;
    font-size: 24px;
`

const FooterSubText = styled.p`
    margin-bottom: 24px;
    font-size: 20px;
`

const Form = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;

@media screen and (max-width: 820px) {
    flex-direction: column;
    width: 80;
}
`

const FooterLinksContainer = styled.div`
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    

    @media screen and (max-width: 820px) {
        padding-top: 32px;
    }
`

const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`

const FooterLinksItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 260px;
    box-sizing: border-box;
    color: #c8d9e3;

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;

    }
`

const FooterLinksTitle = styled.h2`
    margin-bottom: 18px;
    font-size: 20px;
    text-align: center;
    width: 100%;
    color: #d82e49;
   
`

const FooterLink = styled(Link)`
    color: #c8d9e3;
    font-size: 14px;
    text-decoration: none;
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
    direction: ltr;
  

    &:hover {
        color: #bd0b27;
        transition: 0.3s east-out;
    }
`

const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

const SocialMediaWrap = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`


const WebsiteRights = styled.small`
    color: #c8d9e3;
    margin-bottom: 14px;
    font-size: 12px;
`

const SocialIcons = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 240px;
    margin-bottom: 26px;
`

const SocialIconLink = styled.a`
    color: white;
    font-size: 30px;

    &:hover {
        color: #bd0b27;
        transition: 0.3s east-out;
    }
`

const Logo = styled.img`
    /* margin-top: 0.1rem; */
    height: 140px;
    width: 140px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    opacity: 0.7;
    
`

const FooterItem = styled.p`
    color: #c8d9e3;
    font-size: 14px;
    text-decoration: none;
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
    direction: ltr;
`

const FooterEmail = styled.a`
    color: #c8d9e3;
    font-size: 14px;
    text-decoration: none;
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
    direction: ltr;
`


