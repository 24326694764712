import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Context } from '../Context'

const Trigger = () => (
	<Context.Consumer>
		{({ toggleLanguage }) => (
		  <>
			<Button type="button" onClick={toggleLanguage}>
				<FormattedMessage id="language1" />
			</Button>

           
			</>
		)}
	</Context.Consumer>
)


// const Button = styled.button`
// 	color: #fff;
// 	padding: .5rem 0.5rem;
// 	box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
// 	background: #bd0b27;
// 	border-radius: 10px;
// 	font-size: 15px;
// 	font-weight: 600;
// 	letter-spacing: .025em;
// 	text-decoration: none;
// 	cursor: pointer;
// 	border: none;
// 	&:focus {
// 		outline: none;
// 	}
// `

const Button = styled.button`
  border-radius: 50px;
  background: #bd0b27;
  white-space: nowrap;
  padding: 10px 10px;
  color: #fff;
  margin: 0 5px;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-ease-out;
  text-decoration: none;

  &:hover {
      transition: all 0.2s ease-in-out;
      background: #fff;
      color: #bd0b27;
  }

`

export { Trigger }