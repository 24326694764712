import React from "react"
import { Link } from "gatsby"
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute } from './SidebarElements'
import { FormattedMessage } from 'react-intl'
import { Trigger } from "../common/Trigger"


const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='/' onClick={toggle}>
                        <FormattedMessage id="Home" />
                    </SidebarLink>
                    <SidebarLink to='/about' onClick={toggle}>
                        <FormattedMessage id="About" />
                    </SidebarLink>
                    <SidebarLink to='/services' onClick={toggle}>
                        <FormattedMessage id="Services" />
                    </SidebarLink>
                    <SidebarLink to='/projects' onClick={toggle}>
                        <FormattedMessage id="Projects" />
                    </SidebarLink>
                    <SidebarLink to='/impressum' onClick={toggle}>
                        <FormattedMessage id="Impressum" />
                    </SidebarLink>
                    <SidebarLink to='/contact' onClick={toggle}>
                        <FormattedMessage id="ContactUs" />
                    </SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    {/* <SidebarRoute to='/' onClick={toggle}>Sign In</SidebarRoute> */}
                    <Trigger />
                </SideBtnWrap>
                
            </SidebarWrapper>

        </SidebarContainer>
    )
}

export default Sidebar