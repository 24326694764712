import React, {useEffect} from 'react'
import styled from "styled-components"
import EmailBg from '../assets/photos/email-background.webp'
import { Button } from "./Button"
import { Link } from 'gatsby'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { FormattedMessage } from 'react-intl'

function Email() {

    useEffect( () => {
        Aos.init({})
      }, [])


    return (
        <EmailContainer>
            <EmailContent>
                <h1 data-aos="fade-down"
                        data-aos-delay="50"
                        data-aos-duration="1000">
                        <FormattedMessage id="EmailH1" />
                        </h1>
                <p data-aos="fade-down"
                        data-aos-delay="100"
                        data-aos-duration="1000">
                            <FormattedMessage id="EmailH2" />
                        </p>
            <FormWrap data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-duration="1000">
            <Button round small primary to='/contact'> 
              <FormattedMessage id="EmailBtn" />
            </Button>
            </FormWrap>
            </EmailContent>
        </EmailContainer>
    )
}

export default Email

const EmailContainer = styled.div`
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 35%,
        rgba(0, 0, 0, 0.1) 100%
    ),
    url(${EmailBg}) no-repeat center;
    background-size: cover;
    height: 450px;
    width: 100%;
    padding: 5rem calc((100vw - 1300px) / 2);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center
`


const EmailContent = styled.div`
    width: 80%; 
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        text-align: center;
        margin-bottom: 1rem;
        font-size: clamp(1rem, 5vw, 3rem);
        padding: 0 1rem;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicoder', 'Lucida Grannde', 
        'Lucida Sans', Arial, Helvetica, sans-serif, sans-serif;
    }

    p {
        font-family: 'Trebuchet MS', 'Lucida Sans Unicoder', 'Lucida Grannde', 
        'Lucida Sans', Arial, Helvetica, sans-serif, sans-serif;
        text-align: center;
        font-size: clamp(1rem, 2.5vw, 1.5rem);
        padding: 0 1rem;
        margin-bottom: 2rem;
    }
`


const FormWrap = styled.div`
    
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
    }

`


