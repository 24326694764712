import React, {useState, useEffect} from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import { FaBars } from 'react-icons/fa'
import { MdLanguage } from 'react-icons/md'
import { menuData } from '../data/MenuData'
import { Button } from './Button'
import logo from '../assets/photos/etihad-logo.png'
import esg from '../assets/photos/esg-logo.png'

import { FormattedMessage } from 'react-intl'
import { Trigger } from './common/Trigger'



const Header = ({ toggle }) => {
  const [navbar, setNavbar] = useState(false)

  useEffect(() => {
    if(window.location.pathname) {
      setNavbar(window.location.pathname)
    }

  }, [])


  return (
    <Nav navbar={navbar}>
      {/* <NavLink to="/"><Logo src={esg} alt='etihad'/></NavLink> */}
      <Bars onClick={toggle} />
      <NavBar>
          <NavLink to="/">
            <FormattedMessage id="Home" />
          </NavLink>
          <NavLink to="/about">
            <FormattedMessage id="About" />
          </NavLink>

          <NavLink to="/services">
            <FormattedMessage id="Services" />
          </NavLink>

          <NavLink to="/projects">
            <FormattedMessage id="Projects" />
          </NavLink>

          <NavLink to="/impressum">
            <FormattedMessage id="Impressum" />
          </NavLink>

          <NavLink to="/contact">
            <FormattedMessage id="ContactUs" />
          </NavLink>
        
          <NavLink>
            <Trigger />
          </NavLink>
      </NavBar>

    </Nav>
  )
}



export default Header

const Nav = styled.nav`
    background: ${({ navbar }) => (navbar !== "/" ? "#1a2b3d" : "#1a2b3d")};
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem calc((100vw - 1300px) / 2);
    z-index: 100;
    position: relative;
    font-size: 16px;
   
`

const NavLink = styled(Link)`
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &:hover{
        color: #bd0b27;
    }

    &.active {
      border-bottom: 3px solid #bd0b27;
  }
`

const Logo = styled.img`
    margin-top: 0.1rem;
    height: 66px;
    width: 96px;

    @media screen and (max-width:768px) {
      height: 55px;
      width: 75px;
    }
`

const Bars = styled(FaBars)`
    color: white;
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 80%);
      font-size: 1.8rem;
      cursor: pointer;
    }
`

const NavBar = styled.div`
    color: white;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      display: none;
    }
`

const NavBtn = styled.div`
    display: flex;
    align-items: center;
    margin-right:25px;

    @media screen and (max-width: 768px) {
      display: none;
    }
`

const Language = styled(MdLanguage)`
    color: white;
    &:hover{
        color: #bd0b27;
    }
`





